import React, { VFC } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import {UserProviderWrapper } from './utils/auth-context';
import ProtectedRoute from './utils/protected-route';

import LoginPage from './pages/loginpage';
import AdminPage from './pages/adminpage';
import Layout from './components/layout/layout';
import BackingGuarantee from './pages/backingguarantee'; // midlertidig stengt

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import LogoutPage from './pages/logoutpage';
import OrganisationPage from './pages/organisationpage';
import OrganisationDetailsPage from './pages/organisationdetailspage/organisationdetailspage';
import { MessageProviderWrapper } from './utils/message-context/message-context';
import { LocationMonitorWrapper } from './components/input-schema/reauthenticate';

import './App.css'
import ApplicationListPage from './pages/applicationlistpage/applicationlistpage';
import { isDev } from './utils/devtest';

const CLOSE_VEKSTGARANTIER = false;

library.add(far, fas);

const App: VFC = () => {
    return (
        <div className="app">
            <BrowserRouter>
                <LocationMonitorWrapper>
                    <Layout>
                        <MessageProviderWrapper>
                            <UserProviderWrapper>
                                <Switch>
                                    <Redirect exact from="/" to="applicationlistpage" />
                                    <ProtectedRoute path="/adminpage" exact component={() => <AdminPage />} />
                                    {(!CLOSE_VEKSTGARANTIER || isDev) && <ProtectedRoute path="/vekstgaranti" exact component={() => <BackingGuarantee />} />}
                                    <Route path="/login" exact component={() => <LoginPage />} />
                                    <Route path="/logoutpage" exact component={() => <LogoutPage />} />
                                    <ProtectedRoute path="/organisation" exact component={() => <OrganisationPage />} />
                                    <ProtectedRoute path="/organisationdetails/" exact component={OrganisationDetailsPage} />
                                    <ProtectedRoute path="/organisationdetails/:organisationId" exact component={OrganisationDetailsPage} />
                                    <ProtectedRoute path="/applicationlistpage" exact component={() => <ApplicationListPage />} />
                                </Switch>
                            </UserProviderWrapper>
                        </MessageProviderWrapper>
                    </Layout>
                </LocationMonitorWrapper>
            </BrowserRouter>
        </div>
    )
};


export default App;
