import { IValidation, IValue, IFileValidationArgument, IGroup, IInformation, isValue, isGroup } from './input-schema';
import { addMonths } from 'date-fns';
import { FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import * as Yup from 'yup';
import { createYupSchema } from './yup-schema-creator';

const EMPTY_STRING = '';

enum OtherTransactions {
    ID = 1,
    Type = 1,
    Principal_Amount = 2,
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function customValidate(formikProps: FormikProps<any>, prefix: string, value: any, setWrongOrgNumber: React.Dispatch<React.SetStateAction<boolean>>, wrongOrgNumber: boolean):any  {

    function parseNumber(value) {
        if (typeof value === 'number') {
            return value;
        } else {
            throw new Error(`Invalid type: Expected a number, but received ${typeof value}`);
        }
    }

    if (value.customvalidations !== undefined) {
        
        return (val: any) => {
            const cvalidations: IValidation[] = cloneDeep(value.customvalidations);
            let errorMessage:string|undefined = undefined;
            for (let i = 0; i < cvalidations.length; i++) {
                const cv = cvalidations[i];

                if (cv.type === 'required' && (val === undefined || ('' + val).trim() === '' || ((isNaN(val) || ('' + val).trim() === '0') && value.type === 'date'))) {
                    errorMessage = cv.params[0] as string;
                    break;
                }

                if (cv.type === 'matchesorgnumber') {
                    const match: RegExpMatchArray | null = (val as string).match(cv.params[0] as string);
                    if (match === null) {
                        setWrongOrgNumber(false);
                        errorMessage = cv.params[1] as string;
                        break;
                    }
                }

                if (cv.type === 'orgnumber') {
                    errorMessage = undefined;
                    if (wrongOrgNumber === true) {
                        errorMessage = cv.params[0] as string;
                        break;
                    }
                }

                if (cv.type === 'selectinnovationlevelbasedoncriteria') {
                    if (cv.otherfield !== undefined && cv.otherfield.length === 2) {
                        const checkboxValue = formikProps.values[cv.otherfield[0] as string];
                        if (Array.isArray(checkboxValue)) {
                            if ((cv.otherfield[1] as boolean) === checkboxValue.includes('valueok')) {
                                if (val === '' || val === undefined) {
                                    errorMessage = cv.params[0] as string;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (cv.type === 'atleastonechecked') {
                    errorMessage = '';
                    if (cv.otherfield !== undefined && cv.otherfield.length > 0) {
                        let count = 0;
                        cv.otherfield.forEach((key) => {
                            if (Array.isArray(formikProps.values[key as string]) && formikProps.values[key as string].includes('valueok') === true) {
                                count++;
                            }
                        });
                        if (count === 0) {
                            errorMessage = cv.params[0] as string;
                            break;
                        } else {
                            errorMessage = '';
                            break;
                        }
                    }
                }

                if (cv.type === 'atleastonecheckedwhenparentischecked') {
                    errorMessage = '';
                    if (cv.otherfield !== undefined && cv.otherfield.length > 0) {
                        let count = 0;
                        if (formikProps.values[cv.params[1] as string] && formikProps.values[cv.params[1] as string].includes('valueok')) {
                            cv.otherfield.forEach((key) => {
                                if (Array.isArray(formikProps.values[key as string]) && formikProps.values[key as string].includes('valueok') === true) {
                                    count++;
                                }
                            });
                            if (count === 0) {
                                errorMessage = cv.params[0] as string;
                                break;
                            }
                        }
                    }
                }

                if (cv.type === 'requireddependingonotherfieldsvalue' && (isNaN(val) || val === undefined || ('' + val).trim() === '' || (('' + val).trim() === '0' && value.type === 'date'))) {
                    if (cv.otherfield !== undefined) {
                        if ('notequalto' === (cv.otherfield[0] as string) && formikProps.values[cv.otherfield[1] as string] !== (cv.otherfield[2] as string)) {
                            errorMessage = cv.params[0] as string;
                            break;
                        }
                    }
                }

                if (cv.type === 'matches') {
                    const regexp = new RegExp(cv.params[0] as string);
                    const match = regexp.exec(val as string);
                    if (match === null) {
                        errorMessage = cv.params[1] as string;
                        break;
                    }
                }

                if (cv.type === 'max') {
                    const tmpVal = (val as string).replace(/[ ]/g, '');
                    if (+tmpVal > parseNumber(cv.params[0])) {
                        errorMessage = cv.params[1] as string;
                        break;
                    }
                }

                if (cv.type === 'minlengthifspecified' && (val as string).length > 0 && (val as string).length < parseNumber(cv.params[0])) {
                    errorMessage = cv.params[1] as string;
                    break;
                }

                if (cv.type === 'accountnumber') {
                    if ((val as string).length > 0) {
                        if (/^\d{4}.\d{2}\.\d{5}$/.test(val as string) === false) {
                            errorMessage = cv.params[0] as string;
                            break;
                        }
                    } else {
                        errorMessage = cv.params[1] as string;
                        break;
                    }
                }

                if (cv.type === 'validaccountnumber') {
                    if ((val as string).length > 0) {
                        if (!validateAccountNumberMod11(val as string)) {
                            errorMessage = cv.params[0] as string;
                            break;
                        }
                    }
                }

                if (cv.type === 'transactionsaccountnumber') {
                    const transactionId = getSelectedTransactionValue(prefix, value, cv, OtherTransactions.ID);
                    if (transactionId !== undefined && cv.otherfield !== undefined) {
                        if (formikProps.values[transactionId] !== cv.otherfield[2]) {
                            // Type is chosen - start validating
                            if ((val as string).length > 0) {
                                if (/^\d{4}.\d{2}\.\d{5}$/.test(val as string) === false) {
                                    errorMessage = cv.params[0] as string;
                                    break;
                                }
                            } else {
                                errorMessage = cv.params[1] as string;
                                break;
                            }
                        }
                    }
                }

                if (cv.type === 'transactionsaccountnumber_v2') {
                    const transactionId = getSelectedTransactionValue(prefix, value, cv, OtherTransactions.ID);
                    if (transactionId !== undefined && cv.otherfield !== undefined) {
                        if (formikProps.values[transactionId] !== cv.otherfield[2]) {
                            // Type is chosen - start validating
                            if ((val as string).length === 0) {
                                errorMessage = cv.params[1] as string;
                                break;
                            }
                        }
                    }
                }

                if (cv.type === 'transactiontype') {
                    const transactionId = getSelectedTransactionValue(prefix, value, cv, OtherTransactions.ID);
                    const transactionsAmount = getSelectedTransactionValue(prefix, value, cv, OtherTransactions.Principal_Amount);
                    if (transactionId !== undefined && transactionsAmount !== undefined && cv.otherfield !== undefined) {
                        if ((val as string).length === 0) {
                            if (formikProps.values[transactionId].length > 0 || formikProps.values[transactionsAmount].length > 0) {
                                errorMessage = cv.params[0] as string;
                                break;
                            }
                        }
                    }
                }

                if (cv.type === 'transactionsamount') {
                    // get the id Other_Transactions_0_ID
                    const transactionId = getSelectedTransactionValue(prefix, value, cv, OtherTransactions.ID);
                    if (transactionId !== undefined && cv.otherfield !== undefined) {
                        if (formikProps.values[transactionId] !== cv.otherfield[2]) {
                            if ((val.trim() as string).length === 0) {
                                errorMessage = cv.params[0] as string;
                                break;
                            } else {
                                const tmpVal = (val as string).replace(/[ ]/g, '');
                                if (isNaN(+tmpVal)) {
                                    errorMessage = cv.params[3] as string;
                                    break;
                                }
                                if (+val < parseNumber(cv.params[1])) {
                                    errorMessage = cv.params[2] as string;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (cv.type === 'maxdependingonotherfieldsvalue' && val > cv.params[0]) {
                    if (cv.otherfield !== undefined) {
                        if (formikProps.values[cv.otherfield[0] as string] === (cv.otherfield[1] as string)) {
                            errorMessage = cv.params[1] as string;
                            break;
                        }
                    }
                }

                if (cv.type === 'mindependingonotherfieldsvalue' && val < cv.params[0]) {
                    if (cv.otherfield !== undefined) {
                        if (formikProps.values[cv.otherfield[0] as string] === (cv.otherfield[1] as string)) {
                            errorMessage = cv.params[1] as string;
                            break;
                        }
                    }
                }

                if (cv.type === 'difference') {
                    if (val !== undefined && (val as string).length > 0) {
                        if (cv.otherfield !== undefined) {
                            let otherFieldValue = formikProps.values[cv.otherfield[1] as string];
                            otherFieldValue = otherFieldValue.replace(/[,]/, '.');
                            const thisValue = val.replace(/[,]/, '.');
                            if (!isNaN(+otherFieldValue) && !isNaN(+thisValue)) {
                                if (cv.otherfield[0] === 'greaterthan') {
                                    const difference = +otherFieldValue - +thisValue;
                                    if (difference.toFixed(2) < cv.otherfield[2]) {
                                        errorMessage = cv.params[1] as string;
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        errorMessage = cv.params[0] as string;
                        break;
                    }
                }

                if (cv.type === 'maxdependenton') {
                    if (cv.dependencies !== undefined) {
                        for (const d of cv.dependencies) {
                            if (d.operator === 'lessthanorequal') {
                                let siblingValue = 0;
                                if (d.id in formikProps.values && !isNaN(+formikProps.values[d.id])) {
                                    siblingValue = +formikProps.values[d.id];
                                }

                                if (val !== undefined && +val > 0) {
                                    if (+val > siblingValue) {
                                        errorMessage = cv.params[0] as string;
                                        console.log(`maxdependenton: ${val} is greater than ${siblingValue}`);
                                    }
                                }
                            } else if (d.operator === 'lessthanorequal2') {
                                // val must be greater than or equal to val[id]
                                let siblingValue = 0; // not set for date
                                if (d.id in formikProps.values && !isNaN(formikProps.values[d.id])) {
                                    siblingValue = +formikProps.values[d.id];
                                }

                                let addMonthsFromSiblingValue = 0;
                                if (d.addmonthsfromid !== undefined) {
                                    if (d.addmonthsfromid in formikProps.values && !isNaN(formikProps.values[d.addmonthsfromid])) {
                                        addMonthsFromSiblingValue = +formikProps.values[d.addmonthsfromid];
                                    }
                                }

                                if (val !== undefined && +val > 0) {
                                    if (+val > addMonths(new Date(siblingValue), addMonthsFromSiblingValue).valueOf() && siblingValue !== 0) {
                                        errorMessage = cv.params[0] as string;
                                    }
                                }
                            } else if (d.operator === 'greaterthan') {
                                // val must be greater than or equal to val[id]
                                let siblingValue = 0; // not set for date
                                if (d.id in formikProps.values && !isNaN(formikProps.values[d.id])) {
                                    siblingValue = +formikProps.values[d.id];
                                }
                                if (val !== undefined && +val > 0) {
                                    if (+val <= siblingValue && siblingValue !== 0) {
                                        errorMessage = cv.params[0] as string;
                                    }
                                }
                            } else if (d.operator === 'greaterthanorequal') {
                                // val must be greater than or equal to val[id]
                                let siblingValue = 0; // not set for date
                                if (d.id in formikProps.values && !isNaN(formikProps.values[d.id])) {
                                    siblingValue = +formikProps.values[d.id];
                                }
                                if (val !== undefined && +val > 0) {
                                    if (+val < siblingValue && siblingValue !== 0) {
                                        errorMessage = cv.params[0] as string;
                                    }
                                }
                            }
                        }
                    }

                    if (errorMessage !== undefined) {
                        break;
                    }
                }

                if (cv.type === 'mindecimals' && +(val as string).replace(/[,]/, '.') < parseNumber(cv.params[0])) {
                    errorMessage = cv.params[1] as string;
                    break;
                }

                if (cv.type === 'greaterthandecimals' && +(val as string).replace(/[,]/, '.') <= parseNumber(cv.params[0])) {
                    errorMessage = cv.params[1] as string;
                    break;
                }

                if (cv.type === 'min') {
                    const tmpVal = (val as string).replace(/[ ]/g, '');
                    if (+tmpVal < parseNumber(cv.params[0])) {
                        errorMessage = cv.params[1] as string;
                        break;
                    }
                }

                if (cv.type === 'minlength') {
                    const tmpVal = val as string;
                    if (tmpVal.length < parseNumber(cv.params[0])) {
                        errorMessage = cv.params[1] as string;
                        break;
                    }
                }

                if (cv.type === 'maxlength') {
                    const tmpVal = val as string;
                    if (tmpVal.length > parseNumber(cv.params[0])) {
                        errorMessage = cv.params[1] as string;
                        break;
                    }
                }

                if (cv.type === 'typeError' && isNaN(val) && cv.params[0] === 'number') {
                    errorMessage = cv.params[1] as string;
                    break;
                }
            }
            return errorMessage;
        };
    }
}

export function getSelectedTransactionValue(prefix: string, value: IValue, cv: IValidation, fieldId: number) {
    if (cv !== undefined && cv.otherfield !== undefined) {
        // get the id Other_Transactions_0_ID
        const match: RegExpMatchArray | null = ((prefix + value.id) as string).match(/^Other_Transactions_([0-9]+).*$/);

        if (match !== null) {
            const otherFieldId = (cv.otherfield[0] as string)
                .concat('_')
                .concat(match[1])
                .concat('_')
                .concat(cv.otherfield[fieldId] as string);
            return otherFieldId;
        }
    }
    return undefined;
}

// NB: string because Formiks field is string. If it changes type it will force a reset
export function customValidateFiles(fileValidationArgs: string): any {
    if (fileValidationArgs !== undefined && fileValidationArgs !== '') {
        const args: IFileValidationArgument = JSON.parse(fileValidationArgs);
        if (args.minFiles !== undefined && args.value < args.minFiles) {
            const fil = args.minFiles === 1 ? 'fil' : 'filer';
            const count = args.minFiles === 1 ? 'en' : args.minFiles === 2 ? 'to' : args.minFiles === 3 ? 'tre' : args.minFiles;
            return `Feltet må ha minst ${count} ${fil}`;
        } else if (args.maxFiles !== undefined && args.value > args.maxFiles) {
            const fil = args.maxFiles === 1 ? 'fil' : 'filer';
            const count = args.maxFiles === 1 ? 'en' : args.maxFiles === 2 ? 'to' : args.maxFiles === 3 ? 'tre' : args.maxFiles;
            return `Feltet kan maksimalt ha ${count} ${fil}`;
        } else if (args.errorUploadingFiles) {
            return 'En eller flere filer ble ikke lastet opp';
        } else {
            return undefined;
        }
    }
}

export function customValidateCheckbox(value: any): any {
    if (!(value && value.includes('valueok'))) {
        return 'Du må godkjenne for å gå videre';
    }
}

/* eslint-disable @typescript-eslint/ban-types */
export function generateYupSchema(inputValues: (IGroup | IValue | IInformation)[]): Yup.ObjectSchema<object> | string {
    if (inputValues === undefined) {
        return EMPTY_STRING;
    }

    const reduceValues = inputValues.filter((f) => isValue(f));

    const groupValues: IGroup[] = inputValues.filter((f) => isGroup(f)) as IGroup[];
    groupValues.forEach((group) => {
        group.values.forEach((groupValue) => {
            if (group.rows !== undefined && group.rows > 0) {
                for (let i = 0; i < group.rows; i++) {
                    const valueCopy = cloneDeep(groupValue);
                    valueCopy.id = group.id + '_' + i + '_' + groupValue.id;
                    reduceValues.push(valueCopy);
                }
            } else if (groupValue.standaloneid !== undefined && groupValue.standaloneid === true) {
                const valueCopy = cloneDeep(groupValue);
                valueCopy.id = groupValue.id;
                reduceValues.push(valueCopy);
            } else {
                const valueCopy = cloneDeep(groupValue);
                valueCopy.id = group.id + '_' + groupValue.id;
                reduceValues.push(valueCopy);
            }
        });
    });

    const yupSchema = reduceValues.reduce(createYupSchema, {});
    return Yup.object().shape(yupSchema);
}

function validateAccountNumberMod11(accountNumber: string) {
    const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    if (accountNumber === undefined) {
        return false;
    }
    const accountNumberWithoutSpacesAndPeriods = accountNumber.replace(/[\s.]+/g, '');
    if (accountNumberWithoutSpacesAndPeriods.length !== 11) {
        return false;
    } else {
        const checkSum = parseInt(accountNumberWithoutSpacesAndPeriods.charAt(10), 10);
        const accountNumberWithoutCheckSum = accountNumberWithoutSpacesAndPeriods.substring(0, 10);
        let sum = 0;
        for (let index = 0; index < 10; index++) {
            sum += parseInt(accountNumberWithoutCheckSum.charAt(index), 10) * weights[index];
        }
        const reminder = sum % 11;
        return checkSum === (reminder === 0 ? 0 : 11 - reminder);
    }
}
