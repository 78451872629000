import React, { ReactElement, useEffect, VFC } from 'react';
import { FieldAttributes } from 'formik';
import cn from 'classnames';
import './reflect-summary-cl.scss';
import { timer } from 'rxjs';
import Collapse from '@kunukn/react-collapse';
import FaIcon from '../../fa-icon';
import SchemaDefinition from '../../../assets/schema/schema-backing-v3.json';
import { IValue, isValue, isGroup, IGroup, IPage, ISchema, IFileValidationArgument } from '../../input-schema/input-schema';
import { createNonOpinionatedDisplayDate } from '../../input-schema/payload';
import { FileItemExtended } from '../input-dropzone-cl/input-dropzone-cl';
import { cloneDeep } from 'lodash';

export interface ISummaryState {
    expanded: boolean;
}

interface ISummaryItem {
    id: string;
    label: string;
    value: string;
    suffix: string;
    changeable: boolean;
}


const ReflectSummaryCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    useEffect(() => {
        if (fieldAttributes.contactinformation[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name] !== fieldAttributes.contactinformation[fieldAttributes.field.name]) {
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, fieldAttributes.contactinformation[fieldAttributes.field.name]);
            timer(1000).subscribe(() => {
                fieldAttributes.form.validateField(fieldAttributes.field.name);
            });
        }
    }, [fieldAttributes.contactinformation, fieldAttributes.field.name, fieldAttributes.form]);

    function goToPage(index: number) {
        fieldAttributes.setsummarypageactive({ active: true, summaryPageId: fieldAttributes.pageid });
        fieldAttributes.setpageid(index);
    }

    function toggleCollapseElement(index: any) {
        if (fieldAttributes.collapsed[index].expanded === true) {
            fieldAttributes.collapsed[index].expanded = false;
            fieldAttributes.setcollapsed({ ...fieldAttributes.collapsed });
            return false;
        }
        fieldAttributes.collapsed[index].expanded = true;
        fieldAttributes.setcollapsed({ ...fieldAttributes.collapsed });

        return false;
    }

    function generateSummaryItems(page: IPage) {
        const summaryItems: ISummaryItem[] = [];

        page.values.forEach((fieldItem: any) => {
            if (isValue(fieldItem)) {
                if (fieldItem.type !== 'reflect-summary' && fieldItem.type !== 'information') {
                    const label = fieldItem.label + '' === '' ? fieldItem.labelSecondary : fieldItem.label + '';
                    const item: ISummaryItem | boolean = createSummaryItem(fieldItem.id, fieldItem, label);
                    if (item !== false) {
                        summaryItems.push(item);
                    }
                }
            } else if (isGroup(fieldItem)) {
                if (fieldItem.rows !== undefined) {
                    for (let index = 0; index < fieldItem.rows; index++) {
                        fieldItem.values.forEach((_value) => {
                            const item: ISummaryItem | boolean = createSummaryItem((fieldItem as IGroup).id + '_' + index + '_' + _value.id, _value);
                            if (item !== false) {
                                summaryItems.push(item);
                            }
                        });
                    }
                } else {
                    fieldItem.values.forEach((_value) => {
                        if (_value.type === 'group-checkbox') {
                            const val = fieldAttributes.form.values[(fieldItem as IGroup).id + '_' + _value.id];
                            if (val !== undefined && (val as []) !== undefined && val.length > 0) {
                                const displayValue = val.includes('valueok') ? 'Ja' : 'Nei';
                                const item: ISummaryItem = {
                                    id: (fieldItem as IGroup).id + '_' + _value.id,
                                    label: _value.label + '',
                                    value: displayValue,
                                    suffix: _value.suffix === undefined ? '' : _value.suffix,
                                    changeable: _value.changeable === undefined ? false : _value.changeable,
                                };
                                summaryItems.push(item);
                            }
                        } else if (_value.standaloneid !== undefined && _value.standaloneid === true) {
                            if (!(_value.removefrompayload !== undefined && _value.removefrompayload === true)) {
                                const item: ISummaryItem | boolean = createSummaryItem(_value.id, _value);
                                if (item !== false) {
                                    summaryItems.push(item);
                                }
                            }
                        } else {
                            const item: ISummaryItem | boolean = createSummaryItem((fieldItem as any).id + '_' + _value.id, _value);
                            if (item !== false) {
                                summaryItems.push(item);
                            }
                        }
                    });
                }
            }
        });
        return summaryItems;
    }

    function generateSummary(): ReactElement {
        function summarySort(a: IPage, b: IPage) {
            return a.summaryId - b.summaryId;
        }

        if (SchemaDefinition !== undefined && SchemaDefinition.pages !== undefined) {
            let summaryPages = cloneDeep((SchemaDefinition as ISchema).pages);
            summaryPages = summaryPages.sort(summarySort);

            const sections: any[] = summaryPages.map((page, index) => {
                // Generate id's and values for each page
                const summaryPage = summaryPages[index];
                const summaryItems = generateSummaryItems(summaryPage);

                let values2: any[] = [];
                if (summaryItems !== undefined && summaryItems.length > 0) {
                    values2 = summaryItems.map((item: ISummaryItem, i) => {
                        return (
                            <div className={cn('summary-item', { last: i === summaryItems.length - 1 })} key={`item-${i}`}>
                                <div>
                                    <div className="summary-item-header">{item.label}</div>
                                    <div className="summary-item-value">
                                        {item.value} {item.suffix}
                                    </div>
                                </div>
                                {item.changeable && (
                                    <div className="summary-item-button wrapper">
                                        <div className={cn('summary button')} tabIndex={0} onKeyDown={(e) => keyDownHandlerGoToPage(e, summaryPage.id)} onClick={() => goToPage(summaryPage.id)}>
                                            <FaIcon name="edit" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    });
                }

                if (page.changeable === true) {
                    return (
                        <div key={'page' + index}>
                            <div className="summary section">
                                <div className="summary header">{page.title}</div>
                                <div className="summary button wrapper">
                                    <div className={cn('summary button', { open: fieldAttributes.collapsed[index].expanded === true })} tabIndex={0} onKeyDown={(e) => keyDownHandlerCollapse(e, index)} onClick={() => toggleCollapseElement(index)}>
                                        <FaIcon name="plus" />
                                    </div>
                                </div>
                            </div>

                            <Collapse isOpen={fieldAttributes.collapsed[index].expanded} transition={`height 300ms cubic-bezier(.4, 0, .2, 1)`}>
                                <div>{values2}</div>
                            </Collapse>
                        </div>
                    );
                }
            });
            return <>{sections}</>;
        }
        return <></>;
    }

    function keyDownHandlerCollapse(e: any, index: number) {
        if (e.keyCode === 13 || e.keyCode === 32) {
            toggleCollapseElement(index);
            e.preventDefault();
            return false;
        }
    }

    function keyDownHandlerGoToPage(e: any, pageId: number) {
        if (e.keyCode === 13 || e.keyCode === 32) {
            goToPage(pageId);
            e.preventDefault();
            return false;
        }
    }

    function createSummaryItem(id: string, _value: IValue, label?: string) {
        if (fieldAttributes.form.values[id] === undefined || fieldAttributes.form.values[id] === '') {
            return false;
        }
        let item: ISummaryItem = {
            id: id,
            label: label !== undefined ? label : _value.label + '',
            value: fieldAttributes.form.values[id],
            suffix: _value.suffix === undefined ? '' : _value.suffix,
            changeable: _value.changeable === undefined ? false : _value.changeable,
        };
        if (_value.format !== undefined && _value.format.type === 'amount') {
            item = {
                id: id,
                label: _value.label + '',
                value: fieldAttributes.form.values[id],
                suffix: 'kr',
                changeable: _value.changeable === undefined ? false : _value.changeable,
            };
        } else if (_value.type === 'date') {
            item = {
                id: id,
                label: _value.label + '',
                value: createNonOpinionatedDisplayDate(fieldAttributes.form.values[id]),
                suffix: '',
                changeable: _value.changeable === undefined ? false : _value.changeable,
            };
        } else if (_value.type === 'checkbox') {
            const val = fieldAttributes.form.values[id];
            if (val !== undefined && (val as []) !== undefined && val.length > 0) {
                const displayValue = val.includes('valueok') ? 'Ja' : 'Nei';
                item = {
                    id: id,
                    label: label !== undefined ? label : _value.label + '',
                    value: displayValue,
                    suffix: _value.suffix === undefined ? '' : _value.suffix,
                    changeable: _value.changeable === undefined ? false : _value.changeable,
                };
            }
        } else if (_value.type === 'files') {
            const val: IFileValidationArgument = JSON.parse(fieldAttributes.form.values[id]);
            let displayFiles: string[] | never[] = [];
            if (val.files !== undefined && val.files.length > 0) {
                displayFiles = val.files.map((f: FileItemExtended) => f.fileName);
            } else {
                displayFiles = ['Ingen filer opplastet'];
            }
            item = {
                id: id,
                label: label !== undefined ? label : _value.label + '',
                value: displayFiles.join(', '),
                suffix: _value.suffix === undefined ? '' : _value.suffix,
                changeable: _value.changeable === undefined ? false : _value.changeable,
            };
        }
        return item;
    }

    return generateSummary();
}

export default ReflectSummaryCl;
