import React, { useState, useEffect, VFC } from 'react';
import { FieldAttributes } from 'formik';
import './input-group-checkbox-cl.scss';
import { Checkbox, ErrorMessage } from '@in/component-library';
import cn from 'classnames';

export interface Option {
    value: string;
    text: string;
    selected: boolean;
    disabled: boolean;
}

const InputGroupCheckboxCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    interface ICheckParentDecision {
        subId: string;
        checkParent: boolean;
        parentId: string | undefined;
    }

    function getCheckParentDecision(id: string) {
        if (id !== undefined) {
            const subIdIndex = id.lastIndexOf('_');
            if (subIdIndex > -1) {
                const groupId = id.substring(0, subIdIndex + 1);
                const subId = id.substring(subIdIndex + 1);
                const parentSubId = subId.length > 1 ? subId.substring(0, 1) : undefined;
                return {
                    subId: subId,
                    checkParent: subId.length > 1,
                    parentId: parentSubId !== undefined ? groupId.concat(parentSubId) : undefined,
                } as ICheckParentDecision;
            } else {
                return undefined;
            }
        }
    }

    const handleChange = (e: any) => {
        fieldAttributes.form.handleChange(e); // This goes off and eventually does what is expected. In the meantime we use e directly
        fieldAttributes.form.touched[fieldAttributes.field.name] = true;
        // Set the current formik checkbox
        if (e.target.checked === true) {
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, ['valueok']);
            fieldAttributes.form.values[fieldAttributes.field.name] = ['valueok'];
        } else {
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, []);
            fieldAttributes.form.values[fieldAttributes.field.name] = [];
        }

        // Handle parent and sub box manipulations
        const subId: ICheckParentDecision | undefined = getCheckParentDecision(fieldAttributes.field.name);
        if (subId !== undefined) {
            const siblingChecked = anySiblingsChecked(subId);
            if (e.target.checked === true && subId.checkParent === true) {
                fieldAttributes.form.setFieldValue(subId.parentId, ['valueok']);
                fieldAttributes.form.values[fieldAttributes.field.name] = ['valueok'];
            } else if (e.target.checked === false && subId.checkParent === true) {
                // If a child-checkbox was unchecked, do the following:
                // Check if all sibling childs are unchecked. If so, uncheck the parent.
                // If all were unchecked, uncheck the parent too
                if (siblingChecked === false) {
                    if (fieldAttributes.form.values[subId.parentId as string]?.length > 0) {
                        fieldAttributes.form.values[subId.parentId as string] = [];
                        fieldAttributes.form.setFieldValue(subId.parentId, []);
                    }
                }
            } else if (e.target.checked === false && subId.checkParent === false) {
                // Turn off all the children checkboxes
                for (let i = 1; i < 20; i++) {
                    // 20 should suffice
                    const subCheckboxId = fieldAttributes.field.name.concat(i);
                    if (subCheckboxId in fieldAttributes.form.values) {
                        if (fieldAttributes.form.values[fieldAttributes.field.name]?.length == 0) {
                            fieldAttributes.form.setFieldValue(subCheckboxId, []);
                            fieldAttributes.form.values[fieldAttributes.field.name] = [];
                        }
                    }
                }
            }
        }
        setTimeout(() => {
            fieldAttributes.form.validateField(fieldAttributes.field.name);
            fieldAttributes.form.validateForm();
        }, 200);
    };
    function anySiblingsChecked(subId: ICheckParentDecision) {
        if (subId.checkParent === true) {
            const idFragment: RegExpMatchArray | null = fieldAttributes.field.name.match(/^A_1_15_Innovation_Criteria_IEU_([ABCDEF])(\S*)$/);
            let siblingChecked = false;
            if (idFragment !== null) {
                // Check no more than 20
                for (let i = 1; i < 20; i++) {
                    const siblingCheckboxId = 'A_1_15_Innovation_Criteria_IEU_'.concat(idFragment[1].concat(i.toString()));
                    if (siblingCheckboxId in fieldAttributes.form.values) {
                        if (fieldAttributes.form.values[siblingCheckboxId].includes('valueok')) {
                            siblingChecked = true;
                        }
                    }
                }
            }
            return siblingChecked;
        }
    }

    function getHelpTextRaw(fieldAttributes: any) {
        if (fieldAttributes === undefined || fieldAttributes.texts === undefined || fieldAttributes.texts.tooltip === undefined) {
            return undefined;
        }
        return fieldAttributes.texts.tooltip;
    }

    const initialOptions: Option[] = [
        {
            text: fieldAttributes.label,
            selected: fieldAttributes.form.values[fieldAttributes.field.name].includes('valueok'),
            value: 'valueok',
            disabled: fieldAttributes.field.name === "A_1_15_Innovation_Criteria_IEU_C9"
        },
    ];

    useEffect(() => {
        if (fieldAttributes.form.values[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name][0] !== undefined) {
            const changedOption: Option[] = [
                {
                    text: fieldAttributes.label,
                    selected: fieldAttributes.form.values[fieldAttributes.field.name].includes('valueok'),
                    value: 'valueok',
                    disabled: fieldAttributes.field.name === "A_1_15_Innovation_Criteria_IEU_C9"
                },
            ];
            setOptions([...changedOption]);
        } else {
            const changedOption: Option[] = [
                {
                    text: fieldAttributes.label,
                    selected: false,
                    value: 'valueok',
                    disabled: fieldAttributes.field.name === "A_1_15_Innovation_Criteria_IEU_C9"
                },
            ];
            setOptions([...changedOption]);
        }
    }, [fieldAttributes.field.name, fieldAttributes.form, fieldAttributes.label]);

    const [options, setOptions] = useState(initialOptions);

    function showError() {
        if (fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name] && fieldAttributes.showvalidationerrors) {
            return fieldAttributes.form.errors[fieldAttributes.field.name];
        }
        return undefined;
    }

    function hasChildren() {
        if (fieldAttributes.form.values[fieldAttributes.field.name + 1]) {
            return true;
        }
        return false;
    }

    function checkIfVisible() {
        const idFragment: RegExpMatchArray | null = fieldAttributes.field.name.match(/^A_1_15_Innovation_Criteria_IEU_[ABCD](\S*)$/);

        if (idFragment !== null) {
            if (idFragment[1] !== undefined && +idFragment[1] > 0) {
                if (!fieldAttributes.form.values[(fieldAttributes.field.name as string).substring(0, fieldAttributes.field.name.length - idFragment[1].length)].includes('valueok')) {
                    return 'innovationCriteriaInvisible';
                }
            }
        }
        return '';
    }

    return (
        <div className={checkIfVisible()}>
            {showError() !== undefined && hasChildren() && <ErrorMessage className={cn('errorText')} id={`error-${fieldAttributes.field.name}`} errorMessage={showError()} />}
            <div className={cn('checkboxNormal', { [`${fieldAttributes.checkboxClassName}`]: fieldAttributes.checkboxClassName !== undefined })}>
                <div className="oneline">
                    <Checkbox
                        name={fieldAttributes.field.name}
                        helpTextRich={getHelpTextRaw(fieldAttributes)}
                        options={options}
                        onChange={(e: any): void => {
                            handleChange(e);
                        }}
                        onBlur={fieldAttributes.form.handleBlur}
                    />
                </div>
                {showError() !== undefined && !hasChildren() && <ErrorMessage className={cn('errorText tabbed')} id={`error-${fieldAttributes.field.name}`} errorMessage={showError()} />}
            </div>
        </div>
    );
};

export default InputGroupCheckboxCl;
