import React, { useContext, useState, useEffect } from 'react';
import { Button, ButtonSize } from '@in/component-library';
import './applicationlistpage.scss';
import { NavLink } from 'react-router-dom';
import { ILoggedOnUser, UserContext } from '../../utils/auth-context';
import { resetSchemaValues } from '../../components/input-schema/input-schema';
import { IApplicationContext, IDispatchAction, ApplicationContext } from '../../components/input-schema/application-context';
import FaIcon from '../../components/fa-icon';
import cn from 'classnames';
import Axios from 'axios';
import moment from 'moment';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { reAuthenticate } from '../../components/input-schema/reauthenticate';
import { isDev } from '../../utils/devtest';
import { useOrganisationHasAccess } from '../../components/hooks/useOrganisationHasAccess';

const CLOSE_VEKSTGARANTIER = false;

interface IFilters {
    Considering: boolean;
    Considered: boolean;
}

interface ISortOrders {
    Company: boolean;
    ApplicationType: boolean;
    Status: boolean;
    DateN: boolean;
    Region: boolean;
    Documents: boolean;
}

const MaxItemsOnPage = 10;
const ResponsiveWidthThresholdLarge = 1000;
const ResponsiveWidthThresholdMedium = 500;
const MaxTextLength = 60;

// remember the last used filter and sort column
let lastFilterName = 'Considering',
    lastSortedBy = 'DateN';

const ApplicationListPage: any = () => {
    const source = Axios.CancelToken.source();
    const organisationHasAccess = useOrganisationHasAccess(useContext<ILoggedOnUser>(UserContext).user);

    // Session
    function ResetApplicationId(props: { children: React.ReactNode }) {
        const action: IDispatchAction = {
            Command: 'applicationId',
            FieldName: '',
            Value: '',
            Values: {},
        };
        const applicationContextValue: IApplicationContext = useContext(ApplicationContext);
        applicationContextValue.dispatch(action);
        return <div>{props.children}</div>;
    }

    function removeSessionValues() {
        localStorage.removeItem('ApplicationValues');
        source.cancel("Cancel application list");
        resetSchemaValues();
    }

    // welcome message
    const [welcomeMessageClosed, setWelcomeMessageClosed] = useState<boolean>(false);

    function closeWelcomeMessage() {
        setWelcomeMessageClosed(true);
    }

    useEffect(() => {
        const subscription = subscribeToResizeEvent();

        return () => {
            subscription && subscription.unsubscribe();
            source.cancel();
        };
    }, []);

    // Column sort orders
    const [sortOrders, setSortOrders] = useState<ISortOrders>({ ApplicationType: false, Company: false, DateN: false, Documents: false, Region: false, Status: false });

    // Toggles and sorts the table view when column headers are clicked
    // The new sort-setting is remembered
    function toggleSortOrder(name: string) {
        if (!toBeSorted(name)) {
            return;
        }
        const sortAscending = (sortOrders as any)[name] === false ? true : false;
        rememberFilterSettings(name, sortAscending, FilterMode.SortOrders);
        lastSortedBy = name;
    }

    const [rowFilters, setRowFilters] = useState<IFilters>({ Considering: true, Considered: false });

    interface IPaging {
        Page: number;
        ItemsOnPage: number;
    }

    const PagingDefault: IPaging = { Page: 0, ItemsOnPage: MaxItemsOnPage };

    const [paging, setPaging] = useState<IPaging>(PagingDefault);
    useEffect(() => {
        if (sortOrders !== undefined && rowFilters !== undefined && paging !== undefined) {
            loadApplicationList(lastSortedBy, lastFilterName);
        }
    }, [sortOrders, rowFilters, paging]);

    function toBeSorted(key: string) {
        if (key.charAt(0) === '-') {
            return false;
        }
        return true;
    }

    // #responsivity
    interface IFilterView {
        Processing: string;
        Processed: string;
    }

    interface ITableRowView {
        heading: string;
        key: string;
    }
    interface IResponsiveView {
        filter: IFilterView;
        table: ITableRowView[];
    }

    const [width, setWidth] = useState<number>(window.innerWidth);

    const [view, setView] = useState<IResponsiveView>();

    // https://www.quirksmode.org/mobile/tableViewport.html
    function subscribeToResizeEvent() {
        const resizeEvent = fromEvent(window, 'resize');
        const windowWidth = resizeEvent.pipe(map((event: any) => event.target.innerWidth));
        const subscription = windowWidth.subscribe((width) => {
            setWidth(width);
            updateView(width);
        });
        const width = window.innerWidth;
        updateView(width);
        return subscription;
    }

    function updateView(width: number) {
        if (width > ResponsiveWidthThresholdLarge) {
            setView({ ...responsiveViewLarge });
        } else if (width > ResponsiveWidthThresholdMedium) {
            setView({ ...responsiveViewMedium });
        } else {
            setView({ ...responsiveViewSmall });
        }
    }

    const responsiveViewLarge = {
        filter: {
            Processing: 'Søknader til behandling',
            Processed: 'Behandlede søknader',
        },
        table: [
            {
                heading: 'Firma',
                key: '-Company',
            },
            {
                heading: 'Type søknad',
                key: '-ApplicationType',
            },

            {
                heading: 'Status',
                key: 'Status',
            },
            {
                heading: 'Dato',
                key: 'DateN',
            },
            {
                heading: 'Region',
                key: '-Region',
            },
            // {
            //     heading: 'Utløpsdato',
            //     key: '-DateFinal',
            // },
            // {
            //     heading: 'Saldo',
            //     key: '-Saldo',
            // },
            {
                heading: 'Dokumenter',
                key: '-Documents',
            },
        ],
    };

    const responsiveViewMedium = {
        filter: {
            Processing: 'Søknader til behandling',
            Processed: 'Behandlede søknader',
        },
        table: [
            {
                heading: 'Firma',
                key: '-Company',
            },
            {
                heading: 'Type søknad',
                key: '-ApplicationType',
            },

            {
                heading: 'Status',
                key: 'Status',
            },
            {
                heading: 'Dato',
                key: 'DateN',
            },
        ],
    };

    const responsiveViewSmall = {
        filter: {
            Processing: 'Behandling',
            Processed: 'Behandlede',
        },
        table: [
            {
                heading: 'Firma',
                key: '-Company',
            },
            {
                heading: 'Status',
                key: 'Status',
            },
            {
                heading: 'Dato',
                key: 'DateN',
            },
        ],
    };

    // #Filter
    enum FilterMode {
        SortOrders = 0,
        Filters = 1,
    }

    const filterNames = {
        Considered: 'Considered',
        Considering: 'Considering',
    };

    function filterByProgression(progression: string) {
        lastFilterName = progression;
        rememberFilterSettings(progression, getProp(sortOrders, lastSortedBy), FilterMode.Filters);
        setPaging({ ...paging, Page: 0, ItemsOnPage: MaxItemsOnPage });
    }

    function rememberFilterSettings(name: string, sortAscending: boolean, filterMode: FilterMode) {
        if (filterMode === FilterMode.SortOrders) {
            const modifiedSortOrders: ISortOrders = { ApplicationType: false, Company: false, DateN: false, Documents: false, Region: false, Status: false };
            (modifiedSortOrders as any)[name] = sortAscending;
            setSortOrders({ ...sortOrders, ...modifiedSortOrders });
        } else if (filterMode === FilterMode.Filters) {
            // todo
            const modifiedRowFilters: IFilters = { ...rowFilters };
            (modifiedRowFilters as any)[filterNames.Considered] = false;
            (modifiedRowFilters as any)[filterNames.Considering] = false;
            (modifiedRowFilters as any)[name] = true;
            setRowFilters({ ...rowFilters, ...modifiedRowFilters });
        }
    }

    function keyPressProgression(e: any, filterName: string) {
        if ((e.key === 'Enter' || e.key === ' ')) {
            filterByProgression(filterName);
            e.preventDefault();
        }
    }

    // #tools

    // wrapper in cases where obj[prop] can't be used directly
    function getProp(obj: any, prop: string) {
        return obj[prop];
    }

    function getFieldValue(fields: [], id: string) {
        const property: any = fields.filter((f: any) => f.id === id)[0];
        if (property !== undefined && property['value'] !== undefined) {
            // Check for base64 encoding. In some environments there are still data not encoded.
            const value: string = property['value'];
            const match: RegExpMatchArray | null = value.match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/);
            if (match !== null) {
                return Base64.decode(value);
            }
            return value;
        }
        return undefined;
    }

    function adjustLength(text: string, length: number) {
        if (text === undefined) return text;
        if (text.length <= length) return text;
        let cutText = text.substr(0, length);
        const lastSpaceAt = cutText.lastIndexOf(' ');
        if (lastSpaceAt > 0) {
            cutText = cutText.substr(0, lastSpaceAt);
        }
        return cutText + '...';
    }

    // #Load data

    // Service data model - Loaded
    interface IServiceListItem {
        ApplicationId: string;
        ApplicationType: string;
        CaseHandler: string;
        Company: string;
        // DateFinal: string;
        DateN: string;
        Documents: any;
        Region: string;
        // Saldo: string;
        Status: string;
    }

    // Displayed list items - filtered list based on the applicationList
    const [applicationListDisplayed, setApplicationListDisplayed] = useState<IServiceListItem[]>([]);

    function mapSortOrder(sortOrders: ISortOrders, sortColumn: string, progressionFilter: string) {
        if (sortOrders !== undefined && (sortOrders as any)[sortColumn] === true) {
            // we need to flip the sortorder for status, since SL doesn't know about the mapping in frontend.

            if (progressionFilter.indexOf('Refusal') > -1 && sortColumn === 'Status') {
                return 'desc';
            }
            return 'asc';
        }
        if (progressionFilter.indexOf('Refusal') > -1 && sortColumn === 'Status') {
            return 'asc';
        }
        return 'desc';
    }

    // #2 Load
    function loadApplicationList(sortColumn: string, progression?: string) {
        let progressionFilter = '';
        // progression
        if (progression === 'Considered') {
            progressionFilter = 'Approved, Refusal';
        } else if (progression === 'Considering') {
            progressionFilter = 'Unallocated, UnderConsideration';
        }

        // order
        let orderBy = 'submittedOn desc';
        const sortOrder = mapSortOrder(sortOrders, sortColumn, progressionFilter);
        if (sortColumn === 'Status') {
            orderBy = `status ${sortOrder} , submittedOn desc`;
        } else if (sortColumn === 'DateN') {
            orderBy = `submittedOn ${sortOrder}`;
        }

        const uri = `/api/Application?offset=${paging.Page * paging.ItemsOnPage}&take=${paging.ItemsOnPage}&statusFilter=${encodeURIComponent(progressionFilter)}&orderBy=${encodeURIComponent(orderBy)}`;
        Axios.get(uri, { cancelToken: source.token })
            .then((response) => {
                const data: any[] = response.data;
                if (data.length === 0 || data.length < paging.ItemsOnPage) {
                    setStopPagingUpwards(true);
                } else {
                    setStopPagingUpwards(false);
                }
                const serviceList: IServiceListItem[] = (response as any).data.map((item: any) => ({
                    Status: item.status,
                    ApplicationType: getFieldValue(item.content.fields, 'A_2_1_1_Final_Recipient_Transaction_Type'),
                    Region: getFieldValue(item.content.fields, 'Sub_Intermediary_Region'),
                    CaseHandler: getFieldValue(item.content.fields, 'Sub_Intermediary_Contact_Name') + ' ' + getFieldValue(item.content.fields, 'Sub_Intermediary_Contact_Name_Family'),
                    DateN: item.info.submittedOn,
                    Company: getFieldValue(item.content.fields, 'A_1_2_Final_Recipient_Name'),
                    Documents: item.attachments,
                    ApplicationId: item.info.id
                    // DateFinal: "Utløpsdato",
                    // Saldo: "Saldo"
                }));
                setApplicationListDisplayed([...serviceList]);
            })
            .catch((error) => {
                console.error(error);
                if (error.status === undefined) {
                    if (!Axios.isCancel(error)) {
                        reAuthenticate();
                    }
                }
            });
    }

    // Check when to stop pagination upwards.
    const [stopPagingUpwards, setStopPagingUpwards] = useState<boolean>(false);

    // #3 paging
    function handlePaging(direction: boolean) {
        if (paging.Page === 0 && direction === false) {
            return;
        }
        // max page
        if (stopPagingUpwards === true && direction == true) {
            return;
        }
        setPaging({ ...paging, Page: direction === false ? paging.Page - 1 : paging.Page + 1, ItemsOnPage: MaxItemsOnPage });
    }

    function previousPageExists() {
        if (paging.Page === 0) {
            return false;
        }
        return true;
    }

    // Todo: Fix this when we know maxpages
    function generatePaging() {
        return (
            <div>
                <br></br>
                <br></br>
                {previousPageExists() && (
                    <Button
                        theme="neutral"
                        onClick={() => {
                            handlePaging(false);
                        }}
                    >
                        Forrige
                    </Button>
                )}
                &nbsp;&nbsp;
                {!stopPagingUpwards && (
                    <Button
                        theme="neutral"
                        onClick={() => {
                            handlePaging(true);
                        }}
                    >
                        Neste
                    </Button>
                )}
            </div>
        );
    }

    // #Files
    enum LetterType {
        Receipt = 1,
        Answer = 2,
    }

    function getAttachmentId(chosenFile: number, documents: any[]) {
        if (chosenFile === 1) {
            const foundFile = documents.filter((f) => {
                return f.name.indexOf('Innmelding om Vekstgaranti') > -1;
            });
            if (foundFile.length === 1) {
                return foundFile[0].id;
            }
        } else if (chosenFile === 2) {
            const foundFile = documents.filter((f) => {
                return f.name.indexOf('Tilbud om vekstgaranti') > -1 
                || f.name.indexOf('Avslag av vekstgaranti') > -1 
                || f.name.indexOf('garantierklæring.pdf') > -1 
                || f.name.indexOf('rejection.pdf') > -1 ;
            });
            if (foundFile.length > 0) {
                return foundFile[0].id;
            }
        }
        return undefined;
    }

    // #3download
    function generateDownloadLink(applicationId: string, documents: any, chosenFile: LetterType) {
        const linkName = chosenFile === LetterType.Receipt ? 'Kvittering' : 'Svarbrev';
        if (documents !== undefined && documents.length > 0) {
            const attachmentId = getAttachmentId(chosenFile, documents);
            if (attachmentId !== undefined) {
                return (
                    <div
                        className="fileDownload"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            downloadFile(e, applicationId, attachmentId);
                        }}
                        onKeyPress={(e: any) => {
                            e.stopPropagation();
                            downloadFile(e, applicationId, attachmentId);
                        }}
                    >
                        <div tabIndex={0} className="downloadItem">
                            <div>
                                <FaIcon name="download" regular={false} />
                            </div>
                            <div>
                                <div className="linkName">{linkName}</div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    function downloadFile(e: any, applicationId: string, attachmentId: string) {
        Axios.get('/api/Attachment/' + applicationId + '/' + attachmentId, { responseType: 'blob', cancelToken: source.token })
            .then((response) => {
                let filename = '';
                const contentDisposition: string = response.headers['content-disposition'];
                const contentType: string = response.headers['content-type'];
                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename\*[^;=\n]*=UTF-8''((['"]).*?\2|[^";\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                        if (contentType.indexOf('pdf') > -1 && filename.indexOf('.pdf') === -1) {
                            filename += '.pdf';
                        }
                        let blob;
                        if (contentType !== undefined) {
                            blob = new Blob([response.data], { type: contentType });
                        } else {
                            blob = new Blob([response.data], { type: 'application/octet-stream' });
                        }
                        // showFile(blob);
                        const ref = downloadRef;
                        ref.current.href = URL.createObjectURL(blob);
                        ref.current.download = decodeURI(filename);
                        ref.current.click();
                    }
                }
            })
            .catch((error) => {
                if (error.status === undefined) {
                    reAuthenticate();
                }
            });
    }

    // dom ref
    const [downloadRef] = useState<any>(React.createRef());

    // #view
    function generateColumnHeaders() {
        const columns: JSX.Element[] = [];

        // #4View
        {
            view &&
                view.table.forEach((column) => {
                    columns.push(
                        <div
                            className={cn('applist flexitem ', { wider: column.key === '-Company' }, { wide: column.key === '-ApplicationType' }, { noOutline: column.key.startsWith('-') === true })}
                            key={column.key}
                            onClick={() => {
                                toggleSortOrder(column.key);
                            }}
                            onKeyPress={() => {
                                toggleSortOrder(column.key);
                            }}
                        >
                            <div className={cn('columnName', { noOutline: toBeSorted(column.key) === false }, { selected: lastSortedBy === column.key })} tabIndex={0}>
                                {column.heading}
                            </div>
                            {toBeSorted(column.key) &&
                                (getProp(sortOrders, column.key) ? (
                                    <div className={cn('column', { columnSelected: lastSortedBy === column.key })}>
                                        <FaIcon name="angle-down" />
                                    </div>
                                ) : (
                                    <div className={cn('column', { columnSelected: lastSortedBy === column.key })}>
                                        <FaIcon name="angle-up" />
                                    </div>
                                ))}
                        </div>
                    );
                });
        }
        return columns;
    }

    const statusMapper: any = {
        UnderConsideration: 'Innmeldt',
        Unallocated: 'Innmeldt',
        Approved: 'Godkjent',
        Refusal: 'Avslått',
    };

    function generateRows() {
        const rows: JSX.Element[] = [];
        applicationListDisplayed.map((numList, i) =>
            width > ResponsiveWidthThresholdLarge
                ? rows.push(
                      <div className="applist flexrow" key={i}>
                          <div className="applist flexitem wider" title={numList.Company}>
                              {adjustLength(numList.Company, MaxTextLength)}{' '}
                          </div>
                          <div className="applist flexitem wide" title={numList.ApplicationType}>
                              {numList.ApplicationType}
                          </div>
                          <div className="applist flexitem" title={statusMapper[numList.Status] || numList.Status}>
                              {statusMapper[numList.Status] || numList.Status}
                          </div>
                          <div className="applist flexitem" title={moment(numList.DateN).format('DD.MM.YYYY')}>
                              {moment(numList.DateN).format('DD.MM.YYYY')}
                          </div>
                          <div className="applist flexitem" title={numList.Region}>
                              {numList.Region}
                          </div>
                          {/* <div className="applist flexitem" title={numList.DateFinal}>
                              {numList.DateFinal}
                          </div>
                          <div className="applist flexitem" title={numList.Saldo}>
                              {numList.Saldo}
                          </div> */}
                          <div className="applist flexitem widecentered" title="download document">
                              {generateDownloadLink(numList.ApplicationId, numList.Documents, LetterType.Receipt)}
                              {generateDownloadLink(numList.ApplicationId, numList.Documents, LetterType.Answer)}
                          </div>
                      </div>
                  )
                : width > ResponsiveWidthThresholdMedium
                ? rows.push(
                      <div className="applist flexrow" key={i}>
                          <div className="applist flexitem wider" title={numList.Company}>
                              {adjustLength(numList.Company, MaxTextLength)}{' '}
                          </div>
                          <div className="applist flexitem wide" title={numList.ApplicationType}>
                              {numList.ApplicationType}
                          </div>
                          <div className="applist flexitem" title={statusMapper[numList.Status] || numList.Status}>
                              {statusMapper[numList.Status] || numList.Status}
                          </div>
                          <div className="applist flexitem" title={moment(numList.DateN).format('DD.MM.YYYY')}>
                              {moment(numList.DateN).format('DD.MM.YYYY')}
                          </div>
                      </div>
                  )
                : rows.push(
                      <div className="applist flexrow" key={i}>
                          <div className="applist flexitem wider" title={numList.Company}>
                              {adjustLength(numList.Company, MaxTextLength)}{' '}
                          </div>
                          <div className="applist flexitem" title={statusMapper[numList.Status] || numList.Status}>
                              {statusMapper[numList.Status] || numList.Status}
                          </div>
                          <div className="applist flexitem" title={moment(numList.DateN).format('DD.MM.YYYY')}>
                              {moment(numList.DateN).format('DD.MM.YYYY')}
                          </div>
                      </div>
                  )
        );
        return rows;
    }

    return (
        <UserContext.Consumer>
            {(userContext) => (
                <>
                    <div className="pageLayoutRegularPage">
                        <div>
                            <h1>Søknadsoversikt</h1>
                            <div className={cn('applist closeWrapper', { messageClosed: welcomeMessageClosed })}>
                                <div className="applist infoBoxWrapper">
                                    <p className="applist infoBoxHeader">Hei {userContext.user.displayName}, Velkommen som bruker av vekstgarantiportalen.</p>
                                    <p>
                                        Velkommen til Vekstgarantiportalen! I portalen kan du melde inn nye søknader for vekstgaranti og følge med på søknader som er meldt inn i din region. Du kan laste ned svarbrevet fra oss i listen over søknader.
                                        I menylinjen over finner du mer informasjon om Vekstgaranti-ordningen, pre-kvalifisering av låntakere og statistikk om utnyttet ramme. Vår personvernerklæring ligger helt i bunnen av siden, og her finner du
                                        også vår kontaktinformasjon dersom du trenger hjelp.
                                    </p>
                                </div>

                                <div
                                    className="applist closeIcon"
                                    onClick={(): void => {
                                        closeWelcomeMessage();
                                    }}
                                >
                                    <FaIcon name="times" regular={false} />
                                </div>
                            </div>

                            <div className="applist textFiltersHeader">FILTRER ETTER</div>
                            {/* // todo */}

                            <div className="applist textFilters">
                                <div
                                    tabIndex={0}
                                    onKeyPress={(e) => keyPressProgression(e, 'Considering')}
                                    className={cn('applist filter', { selected: rowFilters.Considering })}
                                    onClick={() => {
                                        filterByProgression('Considering');
                                    }}
                                >
                                    {view?.filter.Processing}
                                </div>
                                <div
                                    tabIndex={0}
                                    onKeyPress={(e) => keyPressProgression(e, 'Considered')}
                                    className={cn('applist filter', { selected: rowFilters.Considered })}
                                    onClick={() => {
                                        filterByProgression('Considered');
                                    }}
                                >
                                    {view?.filter.Processed}
                                </div>
                            </div>
                            <div className="applist">
                                <div className="applist flextable">
                                    <div className="applist flexrow header">{generateColumnHeaders()}</div>
                                    {generateRows()}
                                </div>
                                {generatePaging()}
                            </div>
                        </div>
                        <div>
                            <div className="applist buttonWrapper">
                                <NavLink to="/vekstgaranti">
                                    <ResetApplicationId>
                                        <Button theme="positive" size={ButtonSize.Large} onClick={removeSessionValues} disabled={((CLOSE_VEKSTGARANTIER && !isDev) || (!CLOSE_VEKSTGARANTIER && !organisationHasAccess))}>
                                            Opprett ny søknad
                                        </Button>
                                    </ResetApplicationId>
                                </NavLink>
                           </div>
                        </div>
                    </div>
                    <a style={{ display: 'none' }} ref={downloadRef}></a>
                </>
            )}
        </UserContext.Consumer>
    );
};

export default ApplicationListPage;
