function getUrlParameter(parameter) {
    const expression = '^.*' + parameter + '=([0-9a-z-_]*).*$';
    const regexp = new RegExp(expression, 'i');
    const match = regexp.exec(window.document.location.href);
    if (match && match[1]) {
        return match[1];
    }
    return undefined;
}

// Test a block in dev by adding &test=true to the url
export function devTest() : boolean {
    if (process.env.REACT_APP_DBVG_ENVIRONMENT === 'dev' && getUrlParameter('test') === 'true') {
        return true;
    }
    return false;
}

export const isDev = Boolean(
    window.location.hostname === 'vekstgaranti-dev.innovasjonnorge.no' || window.location.hostname === 'localhost'
);
